import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: "question",
  initialState: {
    navPath: null,
    startTime: "",
    questionAll: {},
    question: {},
    resumeTimer:"2:0:0",
  },
  reducers: {
    navigatePath: (state, { payload }) => {
      state.navPath = payload;
    },
    getStartTime: (state, { payload }) => {
        state.startTime = payload;
    },
    getQuestionAll: (state, { payload }) => {
      state.questionAll = payload;
    },
    getQuestion: (state, { payload }) => {
      state.question = payload;
    },
    updateResumeTimer: (state, { payload }) => {
      state.resumeTimer = payload;
    },
  },
});

export const {
  navigatePath,
  getStartTime,
  getQuestionAll,
  getAssessmentList,  
  getQuestion,
  updateResumeTimer,
} = questionSlice.actions;

export const questionSelector = (state) => state.question;
const questionReducer = questionSlice.reducer;
export default questionReducer;
