import {
  ADMIN_SIGNIN_API,
  API_ROOT,
  GET_ASSESSMENT_API,
  GET_DASHBOARD_API,
  GET_QUESTION_API,
  GET_REPORT_DETAILS_API,
  GET_RESULT_API,
  GET_TIMER_API,
  LOG_OUT_API,
  POST_UPDATE_QUESTION_STATUS_API,
  SUBMIT_ALL,
  UPLOAD_RESULT_API,
} from "../config/constants/apiConstant.js";
import { USER_DATA } from "../config/constants/projectConstant.js";
import {
  DASHBOARD_OVERALL_VIEW,
  DASHBOARD_PAGE,
  SUCCESS_PAGE,
} from "../config/constants/routhPathConstant.js";
import { setUserDetails } from "../redux/slicers/authSlice.js";
import {
  getQuestion,
  getQuestionAll,
  updateResumeTimer,
} from "../redux/slicers/questionSlice.js";
import api from "../utils/apiHelper.js";
import {
  devConsoleLog,
  navigate,
  sessionDestroy,
  storeLocal,
  storeSession,
  successToast,
} from "../utils/projectHelper";

export const adminLogInApi = (
  formData,
  setErr,
  navigate,
  setLoading,
  clearFormValues
) => (dispatch) => {
  api()
    .root(API_ROOT)
    .post(ADMIN_SIGNIN_API)
    .data(formData)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        storeLocal(a?.details?.token);
        storeLocal(a?.details, USER_DATA);
        dispatch(setUserDetails(a?.details));
        clearFormValues();
        setErr("");
        navigate(DASHBOARD_OVERALL_VIEW);
      } else {
        setErr(msg);
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      setErr(msg);
      devConsoleLog(e);
    })
    .send(() => {
      // setLoading(false);
    });
};

/**
 * ? Function Name:           getDashboardApi
 * ? Function Type:           GET API Call Function
 * - Dependencies             candidateId
 * - Description:             To fetch DashboardApi Details
 */
export const getDashboardApi =
  (setLoading, setBtnLoading, setTextLoading, sessionId) => (dispatch) => {
    api()
      .root(API_ROOT)
      .get(`${GET_DASHBOARD_API}?sessionIndex=${sessionId}`)
      .success((a) => {
        const { message: msg = "" } = a;
        if (a.statusCode === 200) {
          storeLocal(a?.details?.token);
          storeSession(a?.details, USER_DATA);
          dispatch(setUserDetails(a?.details));
          setTextLoading && setTextLoading(false);
        }
        setLoading && setLoading(false);
        setBtnLoading && setBtnLoading(false);
      })
      .error((e) => {
        setLoading && setLoading(false);
        setBtnLoading && setBtnLoading(false);
        const { message: msg = "" } = e;
        devConsoleLog(e);
      })
      .send(() => {
        setLoading && setLoading(false);
        setBtnLoading && setBtnLoading(false);
      });
  };

/**
 * ? Function Name:           getQuestionsListApi
 * ? Function Type:           GET API Call Function
 * - Dependencies             assessmentId & candidateId
 * - Description:             To fetch Get Particular CandidateId having QuesitionsLists
 */

export const getQuestionsListApi = (assessmentId, candidateId, setLoading) => (
  dispatch
) => {
  api()
    .root(API_ROOT)
    .get(
      `${GET_ASSESSMENT_API}?assessmentId=${assessmentId}&candidateId=${candidateId}`
    )
    .success((a) => {
      const { message: msg = "" } = a;
      // setLoading(true);
      if (a.statusCode === 200) {
        dispatch(getQuestionAll(a?.details));
        dispatch(updateResumeTimer(a?.details?.remainingTime));
        setLoading(false);
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};


/**
 * ? Function Name:           getQuestionApi
 * ? Function Type:           GET API Call Function
 * - Dependencies             questionId, assessmentId & candidateId
 * - Description:             To fetch Get Particular CandidateId single Question
 */
export const getQuestionApi =
  (assessmentId, candidateId, questionId, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .get(
        `${GET_QUESTION_API}?questionId=${questionId}&assessmentId=${assessmentId}&candidateId=${candidateId}`
      )
      .success((a) => {
        const { details } = a;
        dispatch(getQuestion(details));
        const { message: msg = "" } = a;
        setLoading && setLoading(false);
      })
      .error((e) => {
        const { message } = e;
        setLoading && setLoading(false);
        if (message === "This assessment is already submitted") {
          navigate(DASHBOARD_PAGE);
        }
        const { message: msg = "" } = e;
        devConsoleLog(e);
      })
      .send(() => {
        setLoading && setLoading(false);
      });
  };

/**
 * ? Function Name:           postUpdateQuestionStatusApi
 * ? Function Type:           POST API Call Function
 * - Dependencies             accessToken & updateQuestiondata as formdata, navigateQuestionPath as nextQuestionPath &
 * - Description:             To fetch Get Particular CandidateId update single Question
 */

export const postUpdateQuestionStatusApi = (
  formData,
  navigate,
  nextQuestionPath,
  setLoading,
  allQuestions,
  setSerialBtnLoading
) => (dispatch) => {
  const findQuestionAndUpdate = (data) => {
    const findQuestionsAndReplace = allQuestions.data.questions.map(
      (question) => {
        const { question: singleQuestion, ...rest } = data.data.question;
        return question._id === data.data.question._id ? rest : question;
      }
    );
    const filterData = {
      ...data,
      data: {
        ...data.data,
        questions: findQuestionsAndReplace,
      },
    };

    return filterData;
  };
  api()
    .root(API_ROOT)
    .post(POST_UPDATE_QUESTION_STATUS_API)
    .data(formData)
    .success((a) => {
      nextQuestionPath && navigate(nextQuestionPath);
      // successToast(a?.message);
      dispatch(getQuestionAll(findQuestionAndUpdate(a?.details)));
      // setLoading(false);
      setSerialBtnLoading && setSerialBtnLoading(false);
    })
    .error((e) => {
      // setLoading(false);
      setSerialBtnLoading && setSerialBtnLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      // setLoading(false);
      setSerialBtnLoading && setSerialBtnLoading(false);
    });
};

const sumbitForm = (formData, navigate, setLoading) => {
  const { assessmentId, candidateId, setId } = formData;
  api()
    .root(API_ROOT)
    .post(`${SUBMIT_ALL}`)
    .data(formData)
    .success((a) => {
      const { details } = a;
      const { message: msg = "" } = a;
      if (a.statusCode == 200) {
        navigate(`${SUCCESS_PAGE}/${assessmentId}/${candidateId}/${setId}`);
      }
      setLoading(false);
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};

export const submitAllApi =
  (answerFormData, formData, navigate, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(POST_UPDATE_QUESTION_STATUS_API)
      .data(answerFormData)
      .success((a) => {
        // setLoading(false);
        sumbitForm(formData, navigate, setLoading);
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        devConsoleLog(e);
      })
      .send(() => {
        // setLoading(false);
      });
  };

/**
 * ? Function Name:           logoutApi
 * ? Function Type:           -
 * - Dependencies             -
 * - Description:             To fetch sessionDestroy once logout
 *
 */
export const AdminlogoutApi = (path) => {
  sessionDestroy(path);
};
export const logoutApi = (candidateId, setLoading) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${LOG_OUT_API}?candidateId=${candidateId}`)
    .success((a) => {
      // sessionDestroy(path);
      if (a.statusCode == 200) {
        setLoading && setLoading(false);
        window.location.href = a?.redirectLogoutUrl;
      }
    })
    .error((e) => {
      window.location.href = e?.redirectLogoutUrl;
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};
/**
 * ? Function Name:           getResultApi
 * ? Function Type:           -
 * - Dependencies             -
 * - Description:             To fetch Result Data
 *
 */

export const getResultApi =
  (assessmentId, candidateId, setId, setData, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .get(
        `${GET_RESULT_API}?assessmentId=${assessmentId}&candidateId=${candidateId}&setId=${setId}`
      )
      .success((a) => {
        const { details } = a;
        if (a.statusCode == 200) {
          setData(details);
          setLoading && setLoading(false);
        }
      })
      .error((e) => {
        setLoading && setLoading(false);
        const { message: msg = "" } = e;
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };
export const sendStartTime = (formData, setLoading) => (dispatch) => {
  api()
    .root(API_ROOT)
    .put(`${GET_TIMER_API}`)
    .data(formData)
    .success((a) => {
      if (a.statusCode == 200) {
        const { assessmentId, candidateId } = formData;
        dispatch(getQuestionsListApi(assessmentId, candidateId, setLoading));
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};

export const getReportDetailApi = (
  candidateId,
  setLoading,
  setData,
  ref,
  setDownloadLoading
) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_REPORT_DETAILS_API}?id=${candidateId}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading && setLoading(false);
      if (a.statusCode === 200) {
        setData(a?.details?.candidateDetails);
        // ref && handleDownloadPDF(setDownloadLoading,ref)
      }
    })
    .error((e) => {
      setLoading && setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading && setLoading(false);
    });
};

export const uploadResultAPI = (formData, setLoading) => (dispatch) => {
  api()
    .root(API_ROOT)
    .post(`${UPLOAD_RESULT_API}`)
    .data(formData)
    .success((a) => {
      setLoading(false)
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .upload(() => {
      setLoading(false);
    });
};
