import { lazy } from "react";
import AdminLayout from "../../components/common/layout/AdminLayout";
import {
  CANDIDATE_RESULTS_PAGE,
  CANDIDATE_REGISTRATION_PAGE,
  ADMIN_SIGNIN,
  CANDIDATE_RESULTS_PAGE_TODAY,
  CANDIDATE_REGISTRATION_PAGE_TODAY,
  DASHBOARD_OVERALL_VIEW,
} from "./../constants/routhPathConstant";
import { Link } from "react-router-dom";

const CandidateResults = lazy(() =>
  import("../../pages/admin/candidateManagement/candidateResults")
);
const CandidateRegistration = lazy(() =>
  import("../../pages/admin/candidateManagement/candidateRegistration")
);
const NotFound = lazy(() => import("../../pages/common/notFound"));

const Guest = () => {
  return (
    <div>
      <h1>Hello World (Guest)</h1>
      <Link to={ADMIN_SIGNIN}>Go to Signin</Link>
    </div>
  );
};

const adminRoutes = [
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Guest />,
      },
      {
        path: DASHBOARD_OVERALL_VIEW,
        element: <CandidateResults />,
      },
      {
        path: CANDIDATE_RESULTS_PAGE_TODAY,
        element: <CandidateResults />,
      },
      {
        path: CANDIDATE_REGISTRATION_PAGE_TODAY,
        element: <CandidateRegistration />,
      },
      {
        path: CANDIDATE_RESULTS_PAGE,
        element: <CandidateResults />,
      },
      {
        path: CANDIDATE_REGISTRATION_PAGE,
        element: <CandidateRegistration />,
      },
      {
        path: "*",
        element: <NotFound role="admin" />,
      },
    ],
  },
];

export default adminRoutes;
