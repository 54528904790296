import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    navPath: null,
  },
  reportList: [],
  candidateLists: [],
  assessmentInfo: {},
  candidateCounts: {
    totalCandidateRegCount: 0,
    totalCandidateResult: 0,
    totalCandidateRegCountToday: 0,
    totalCandidateResultToday: 0,
    liveTestAttempt: 0,
    stateWiseCount: {},
    stateWiseReportCount: {},
  },
  overAllMap: [],
  reducers: {
    navigatePath: (state, { payload }) => {
      state.navPath = payload;
    },
    //admin
    getReportLists: (state, { payload }) => {
      state.reportList = payload;
    },
    getCandidateLists: (state, { payload }) => {
      state.candidateLists = payload;
    },
    getCandidateCounts: (state, { payload }) => {
      state.candidateCounts = payload;
    },
  },
});

export const {
  navigatePath,
  getReportLists,
  getCandidateLists,
  getStartTime,
  getAssessmentInfo,
  getCandidateCounts,
} = userSlice.actions;

export const userSelector = (state) => state.user;
const userReducer = userSlice.reducer;
export default userReducer;
