import userRoutes from "./userRoutes";
import guestRoutes from "./guestRoutes";
import adminRoutes from "./adminRoutes";

const ROLES_ROUTES = {
  1: guestRoutes,
  2: userRoutes,
  3: adminRoutes,
};

export const getRoutes = (role) => {
  return ROLES_ROUTES[role];
};
