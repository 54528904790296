import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import {
  ADMIN_SIGNIN,
  SIGNIN,
} from "../../../config/constants/routhPathConstant";
import { activitySelector, setMenu } from "../../../redux/slicers/activitySlice";

import Footer from "./footer";
import NavBar from "./navbar";
import SideBar from "./SideBar";
import "./style.css";
import { ReactComponent as HamMenu } from "../../../assets/icons/hambergermenu.svg";

const noLayout = [ADMIN_SIGNIN];

const AdminLayout = () => {
  const { pathname = "" } = useLocation();
  const dispatch = useDispatch();
  const hasLayout = noLayout.find((item) => pathname.includes(item));

  const handleMenu = () => {
    dispatch(setMenu(true));
  };

  const menuState = useSelector(activitySelector);

  return hasLayout || pathname == SIGNIN ? (
    <Outlet />
  ) : (
    <div className="student-page">
      <NavBar role="admin" />
      <div className="main-dashboard">

        <div style={{ right: `${menuState.isMenuOpen ? "0" : "100%"}` }} className="overlay-sidebar"></div>
        <div className="main-dashboar-layout">
          <div className="main-dashboard-header">
            <div>
              <h1>Welcome Admin</h1>
              <p>Dashboard Overview</p>
            </div>
          </div>
          <div className="main-layout">
            <SideBar />
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
