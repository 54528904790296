import { SUB_ADMIN, SUPER_ADMIN, WEBSITE_RELOGIN } from "./routhPathConstant";

export const DEFAULT_TOKEN = "token";
export const USER_DATA = "userData";
export const USER_DETAILS = "user_detils";

//roles
export const SUPER_ADMIN_ROLE = "super-admin";

//role navigation
export const ROLE_NAV = {
  ADMIN: SUPER_ADMIN,
  SUBADMIN: SUB_ADMIN,
};

export const PREVENT_ROUTES = [WEBSITE_RELOGIN];

export const NETWORK_ERROR = "Network Error";

// Languages
export const LANGUAGES = [
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Marathi", value: "Marathi" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Odiya", value: "Odiya" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Bengali", value: "Bengali" },
];

export const backgroundColorMap = {
  notAttempt: "#F2F2F2",
  answered: "#04d375",
  markForReview: "#a378d9",
  notAnswered: "#FF3F3F",
  answeredMarkForReview: "#000000",
};

export const colorMap = {
  notAttempt: "#263238",
  answered: "white",
  markForReview: "white",
  notAnswered: "white",
  answeredMarkForReview: "#ffffff",
};
