export const DEV = "http://localhost:5000/";
export const PROD = "https://api-ksdc.testaonline.com/";
export const STAGE = "https://api-stagingksdc.testaonline.com/";

export const API_ROOT = STAGE;

export const ADMIN_SIGNIN_API = "api/admin-login";

export const GET_DASHBOARD_API = "dashboard";

export const GET_ASSESSMENT_LIST_API = "api/assessmentlist";

export const GET_ASSESSMENT_API = "api/getassessment";

export const GET_QUESTION_API = "api/getonequestion";

export const POST_UPDATE_QUESTION_STATUS_API = "api/updatequestion";

export const SUBMIT_ALL = "api/submitassessment";

export const LOG_OUT_API = "logout"

export const GET_RESULT_API = "api/getresult"

export const GET_TIMER_API = "capturestarttime"

export const UPLOAD_RESULT_API = "api/uploadResults"

export const GET_REPORT_DETAILS_API = "api/reportDetails"


// Admin
export const FETCH_REPORT_API = "api/candidate-list";

export const REGISTER_CANDIDATE_LIST_API = "api/getResult-list";

export const GET_COUNTS = "api/getDashboardDetails"
