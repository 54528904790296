import { lazy } from "react";
import UserLayout from "../../components/common/layout/UserLayout";
import {
  ASSESSMENT_PAGE,
  GENERAL_INSTRUCTIONS_PAGE,
  SIGNIN,
  SUCCESS_PAGE,
  DASHBOARD_SESSIONID,
  SESSION_ID,
  ADMIN_SIGNIN,
} from "./../constants/routhPathConstant";
import { Outlet } from "react-router-dom";
const Login = lazy(() => import("../../pages/auth/login"));
const Dashboard = lazy(() => import("../../pages/user/dashboard"));
const RegistrationDetails = lazy(() =>
  import("../../pages/user/registrationDetails")
);
const GeneralInstructions = lazy(() =>
  import("../../pages/user/generalInstructions")
);
const Question = lazy(() => import("../../pages/user/questionLists/questions"));
const SuccessPage = lazy(() => import("../../pages/common/success"));

const NotFoundPage = lazy(() => import("../../pages/common/notFound"));
const AdminLogin = lazy(() =>import("../../pages/admin/login"));

const userRoutes = [
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        index: true,
        element: <NotFoundPage />,
      },
      {
        path: "admin-login",
        element: <AdminLogin />,
      },
      {
        path: SESSION_ID,
        element: <RegistrationDetails />,
      },
      {
        path: `${GENERAL_INSTRUCTIONS_PAGE}/:assessmentId`,
        element: <GeneralInstructions />,
      },
      {
        path: `${ASSESSMENT_PAGE}/:assessmentId/:candidateId/:qId`,
        element: <Question />,
      },
      {
        path: `${SUCCESS_PAGE}/:assessmentId/:candidateId/:setId`,
        element: <SuccessPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];

export default userRoutes;
