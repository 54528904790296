import { lazy } from "react";
import { ADMIN_SIGNIN,  } from "../constants/routhPathConstant";
import GuestLayout from "../../components/common/layout/GuestLayout";
const AdminLogin = lazy(() =>import("../../pages/admin/login"));
const Login = lazy(() => import("../../pages/auth/login"));
const NotFoundPage = lazy(()=> import("../../pages/common/notFound"));

const guestRoutes = [
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: ADMIN_SIGNIN,
        element: <AdminLogin />,
      },
      {
        path: "*",
        element: <NotFoundPage/>,
      },
    ],
  },
];

export default guestRoutes;
