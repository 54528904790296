import {
    DEFAULT_TOKEN,
    USER_DATA,
  } from "../config/constants/projectConstant.js";
  import { KSDC_ASSESSMENT_PAGE, SIGNIN } from "../config/constants/routhPathConstant.js";
  import { toast } from "react-toastify";
  import { navigatePath, sessionFail } from "../redux/slicers/activitySlice";
  import store from "../redux/store";
  import validateField from "./validateField.js";
  import Swal from "sweetalert2";
  import { setUserDetails } from "../redux/slicers/authSlice.js";
  //useCommas
  
  export const numberWithCommasString = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  export const numberWithoutCommasString = (x) => {
    const beforeDecimalStr = x.toString()?.split(".");
  
    if (beforeDecimalStr[1] !== undefined) {
      return (
        beforeDecimalStr[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        beforeDecimalStr[1]
      );
    } else {
      return beforeDecimalStr[0]
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  
  export const numberWithCommasTrunc = (x) => {
    const trunc = x?.toFixed(2);
    return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  export const numberWithCommasMath = (x) => {
    const trunc = Math?.trunc(x);
    return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  export const isDev = () => {
    return process.env.NODE_ENV === "development";
  };
  
  export const devConsoleLog = (...a) => {
    if (a) {
      if (isDev()) {
      }
    }
  };
  
  export const storeLocal = (data = "", tokenName = DEFAULT_TOKEN) => {
    if (typeof data === "object") {
      data = JSON.stringify(data);
    }
    window.sessionStorage.setItem(tokenName, data);
  };
  export const storeSession = (data = "", tokenName = "userData") => {
    if (typeof data === "object") {
      data = JSON.stringify(data);
    }
    window.sessionStorage.setItem(tokenName, data);
  };
  export const getLocal = (tokenName = DEFAULT_TOKEN) => {
    const localData = window.sessionStorage.getItem(tokenName);
    let res;
    try {
      res = JSON.parse(localData);
    } catch (err) {
      res = localData;
    }
    return res;
  };
  export const getUserData = (userData = USER_DATA) => {
    const localData = window.sessionStorage.getItem(userData);
    let res = {};
    try {
      res = JSON.parse(localData) || {};
    } catch (err) {
      res = localData || {};
    }
    return res;
  };
  export const getUserDetails = (userData = USER_DATA) => {
    const localData = window.sessionStorage.getItem(userData);
    let res = {};
    try {
      res = JSON.parse(localData) || {};
    } catch (err) {
      res = localData || {};
    }
    return res;
  };
  export const removeLocal = (tokenName = DEFAULT_TOKEN) => {
    window.sessionStorage.removeItem(tokenName);
    return navigate(SIGNIN);
  };
  
  export const sessionDestroy = (path = KSDC_ASSESSMENT_PAGE) => {
    removeLocal();
    removeLocal(USER_DATA);
    dispatcher(sessionFail());
    // navigate(path);
    window.location.href = path;
    dispatcher(setUserDetails({}));
  };
  
  export const uploadPathBuilder = (root, a) => {
    return root + a;
  };
  
  export const navigate = (path) => {
    dispatcher(navigatePath(path));
  };
  
  export const dispatcher = (a) => {
    store.dispatch(a);
  };
  
  export const successToast = (a) =>
    toast.success(a, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  
  export const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  
  export const warningToast = () => {
    toast.warn("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  export const infoToast = () => {
    toast.info("Network Error!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  
  export const errorValidator = (a, setState) => {
    const { name, value = "", password } = a;
    if (value.trim()) {
      const { error = null } = validateField(name, value, password);
      setState((err) => {
        return { ...err, [name]: error };
      });
    } else {
      setState((err) => {
        return { ...err, [name]: null };
      });
    }
  };
  export const errorValidatorPassword = (a, setState) => {
    const { name, value = "" } = a;
    if (value.trim()) {
      const { error = null } = validateField(name, value);
      setState((err) => {
        return { ...err, [name]: error };
      });
    } else {
      setState((err) => {
        return { ...err, [name]: null };
      });
    }
  };
  
  export const errorAlert = (msg) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: msg,
    });
  };
  
  export const SuccessAlert = (msg) => {
    Swal.fire({
      icon: "success",
      title: msg,
      showConfirmButton: false,
      timer: 5000,
    });
  };
  export const ConfirmAlert = (msg, navigate, path) => {
    Swal.fire({
      title: msg,
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "GO BACK TO LOGIN",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(path);
      }
    });
  };
  
  export const capitalizeFunc = (value) => {
    const arr = value.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const capitalizeStr = arr.join(" ");
  
    return capitalizeStr;
  };
  
  export const getTimeDifference = (date) => {
    const currentDate = new Date(); // current date/time
    const pastDate = new Date(date); // the past date/time you want to compare
  
    // calculate the time difference in milliseconds
    const timeDiff = currentDate.getTime() - pastDate.getTime();
  
    // convert the time difference from milliseconds to seconds, minutes, hours, and days
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "a few seconds ago";
    }
  };
  
  export const blockInvalidChar = (event) =>{
    ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault(); // Prevents the default arrow key behavior
    }
  }
  
  export const exportData = (sortedData, columns) => {
    const result =
      sortedData &&
      sortedData?.map((item, ind) => {
        const newObj = {};
        const xyz = Object.keys(item)?.map((val) =>
          columns?.map((col) => {
            if (col?.name == "_id") {
              newObj[col?.label] = ind + 1;
            }
            if (col?.name == val) {
              newObj[col?.label] = item[val];
            }
          })
        );
        return newObj;
      });
    return result;
  };

export function padZero(number) {
  return (number < 10 ? "0" : "") + number;
}
  