import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  ADMIN_SIGNIN,
  SIGNIN,
} from "../../../config/constants/routhPathConstant";
import NavBar from "./navbar";
import Footer from "./footer";

const noLayout = [ADMIN_SIGNIN, SIGNIN];

const UserLayout = () => {
  const { pathname = "" } = useLocation();
  const hasLayout = noLayout.find((item) => pathname.includes(item));
  return hasLayout || pathname == SIGNIN ? (
    <Outlet />
  ) : (
    <div className="user-page">
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default UserLayout;
