export const WEBSITE_RELOGIN = "/re-login";
export const SUPER_ADMIN = "/super-admin";
export const SUB_ADMIN = "/sub-admin";

//Auth Routes
export const ADMIN_SIGNIN = "/admin-login";
export const SIGNIN = "signin";
export const KSDC_ASSESSMENT_PAGE = "https://ksdc.itrackglobal.com/local/assessment/my.php";

// User Routes
export const DASHBOARD_PAGE = "/";
export const CANDIDATE_DETAILS_PAGE = "/details";
export const GENERAL_INSTRUCTIONS_PAGE = "/general-instructions";
export const ASSESSMENT_PAGE = "/assessment";
export const SUCCESS_PAGE = "/success";
export const DASHBOARD_SESSIONID = '/dashboard/:sessionId';
export const SESSION_ID = ":sessionId"

// Admin Routes
export const DASHBOARD_OVERALL_VIEW = "/dashboard"

export const CANDIDATE_REGISTRATION_PAGE_TODAY = "/dashboard/candidate-registration-today";
export const CANDIDATE_RESULTS_PAGE_TODAY = "/dashboard/candidate-results-today";
export const CANDIDATE_RESULTS_PAGE = "/candidate-results";
export const CANDIDATE_REGISTRATION_PAGE = "/candidate-registration";
