const validateField = (
    name,
    value,
    confirmPassword = null,
    oldPassword = null,
    startDate = null
  ) => {
    switch (name) {
      case "firstName":
        if (!value) {
          return "First name is required";
        } else if (!/^[a-zA-Z]+$/.test(value)) {
          return "First name can only contain letters";
        } else if (value.trim().length < 3) {
          return "First name must be at least 3 characters";
        } else if (value.trim().length > 50) {
          return "First name must be at most 50 characters";
        }
        break;
      case "lastName":
        if (!value) {
          return "Last name is required";
        } else if (!/^[a-zA-Z]+$/.test(value)) {
          return "Last name can only contain letters";
        } else if (value.trim().length < 3) {
          return "Last name must be at least 3 characters";
        } else if (value.trim().length > 50) {
          return "Last name must be at most 50 characters";
        }
        break;
      case "email":
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          return "Invalid email address";
        } else if (value && value.trim().length < 5) {
          return "Email must be at least 3 characters";
        } else if (value && value.trim().length > 255) {
          return "Email must be at most 255 characters";
        }
        break;
      case "password":
      case "oldPassword":
        if (!value) {
          return "Password is required";
        } else if (value.trim().length < 4 || value.trim().length > 20) {
          return "Password must be between 4 and 20 characters";
        }
        break;
      case "newPassword":
        if (!value) {
          return "New password is required";
        } else if (value === oldPassword) {
          return "New password must not be the same as the old password";
        } else if (value.trim().length < 8 || value.trim().length > 20) {
          return "New password must be between 8 and 20 characters";
        } else if (!/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*])/.test(value)) {
          return "New password must contain at least one letter, one number, and one special character (!@#$%^&*)";
        }
        break;
      case "confirmPassword":
      case "reEnterPassword":
        if (!value) {
          return "Confirm password is required";
        } else if (value !== confirmPassword) {
          return "Passwords do not match";
        }
        break;
      case "contactNumber":
      case "mobile":
        if (!value) {
          return "Mobile number is required";
        } else if (!/^[0-9]+$/.test(value)) {
          return "Invalid mobile number";
        } else if (value.trim().length !== 10) {
          return "Mobile number must be exactly 10 characters";
        }
        break;
      case "inputOTP":
        if (!value) {
          return "OTP is required";
        } else if (!/^[0-9]+$/.test(value)) {
          return "Invalid OTP number";
        } else if (value.trim().length !== 6) {
          return "OTP must be exactly 6 characters";
        }
        break;
      case "state":
        if (!value) return "State is required";
        break;
      case "adharNumber":
        if (!value) {
          return "Aadhaar number is required";
        } else if (value.trim().length !== 12) {
          return "Aadhaar number must be exactly 12 characters";
        } else if (!/^[0-9]+$/.test(value)) {
          return "Invalid Aadhaar number";
        }
        break;
      case "chooseLanguage":
        if (!value) {
          return "Language is required";
        }
        break;
      case "allotedSlot":
        if (!value) return "Time Slot is required";
        break;
      case "slotDate":
        if (!value) return "Date is required";
        else if (value == "Invalid Date") return "Date is required";
        break;
      case "startDate":
        if (!value) {
          return "Start Date is required";
        }
        break;
      case "endDate":
        if (!value) {
          return "End Date is required";
        } else if (startDate && value < startDate) {
          return "End Date must be after or equal to Start Date";
        }
        break;
      default:
        break;
    }
    return "";
  };
  
  export default validateField;
  
  export const loginValidateField = (name, value) => {
    switch (name) {
      case "email":
        if (!value) {
          return "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          return "Invalid email address";
        } else if (value.trim().length < 5) {
          return "Email must be at least 3 characters";
        } else if (value.trim().length > 255) {
          return "Email must be at most 255 characters";
        }
        break;
      case "contactNumber":
        if (!value) {
          return "Mobile number is required";
        } else if (!/^[0-9]+$/.test(value)) {
          return "Invalid mobile number";
        } else if (value.trim().length !== 10) {
          return "Mobile number must be exactly 10 characters";
        }
        break;
      case "password":
        if (!value) {
          return "Password is required";
        } else if (value.trim().length < 4 || value.trim().length > 20) {
          return "Password must be between 4 and 20 characters";
        }
        break;
      default:
        break;
    }
    return "";
  };
  