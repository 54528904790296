import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LazyLoader from "./components/common/LazyLoader";
import { getRoutes } from "./config/routes";
import { authSelector, setUserDetails } from "./redux/slicers/authSlice";
import { getUserDetails } from "./utils/projectHelper";
import ActivityContainer from "./container/Activity";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const { userDetails } = useSelector(authSelector);
  const dispatch = useDispatch();
  const userData = getUserDetails();

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    dispatch(setUserDetails(userData));
  }, []);
  const routeType = userDetails?.userType == "admin" ? 3 : 2;

  const router = useRoutes(getRoutes(routeType));

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ActivityContainer>
          <LazyLoader>{router}</LazyLoader>
          <ToastContainer />
        </ActivityContainer>
      </ThemeProvider>
    </>
  );
}

export default App;
