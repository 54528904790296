import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import activityReducer from "./slicers/activitySlice";
import authReducer from "./slicers/authSlice";
import userReducer from "./slicers/userSlice";
import guestReducer from "./slicers/guestSlice";
import questionReducer from "./slicers/questionSlice";

const rootReducer = combineReducers({
  guest: guestReducer,
  auth: authReducer,
  user: userReducer,
  activity: activityReducer,
  question: questionReducer,
});

// const whiteList = ["question"];///include  declared in local
// const blacklist = ["guest","auth","user","activity"]; // except declared all stored in local

// const persistConfig = {
//   key: "root",
//   storage,

//   // only persist the state keys that are listed in the whiteList
//   whitelist: whiteList,

//   // do not persist the state keys that are listed in the blacklist
//   blacklist: blacklist,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
// });
// export const persistor = persistStore(store);

const store = configureStore({
  reducer: rootReducer,
});

export default store;
