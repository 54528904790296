import React from "react";
import "./style.css";
import RadiantLogo from "../../../../assets/images/radiant-logo.png";
// import { PulseLoader } from "react-spinners";
// import { Button } from "@mui/material";
// import { useState } from "react";

const Footer = () => {
  return (
    <footer>
      <div className="user-footer">
        <div className="logo-wrapper">
          <h1>Powered by</h1>
          <div className="logo">
            <img src={RadiantLogo} alt="logo" />
          </div>
        </div>
        {/* <div className="help-btn-wrapper">
          <Button onClick={() => handleSubmit()}>
            {loading ? (
              <PulseLoader size="10px" color="white" />
            ) : (
              "Need Help ?"
            )}
          </Button>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
